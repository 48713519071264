import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import gbfLogo from '../assets/gbflogo.png'; // Logotipo do Global Force Bank
import debenturesData from '../assets/debentures-offers.json'; // Importando o arquivo JSON com as ofertas

// Estilização com styled-components
const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  font-family: Arial, sans-serif;
  color: #333;
`;

const Title = styled.h1`
  color: #244c3c;
  font-size: 2.5rem;
  margin-bottom: 20px;
`;

const OfferList = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: 20px;
  width: 100%;
  max-width: 800px;
`;

const OfferCard = styled.div`
  background-color: #ffffff;
  border: 2px solid #244c3c;
  border-radius: 12px;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  transition: transform 0.3s ease;

  &:hover {
    transform: translateY(-5px);
  }
`;

const OfferTitle = styled.h2`
  color: #244c3c;
  font-size: 1.5rem;
  margin-bottom: 10px;
`;

const OfferDetails = styled.div`
  font-size: 1rem;
  color: #555;
  margin-bottom: 20px;
`;

const OfferValue = styled.p`
  font-weight: bold;
  font-size: 1.2rem;
  color: #ddba33;
`;

const InvestButton = styled.button`
  background-color: #244c3c;
  color: #ffffff;
  border: none;
  padding: 10px 20px;
  font-size: 1rem;
  font-weight: bold;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #ddba33;
    color: #244c3c;
  }
`;

// Footer com cor de fundo e fonte ajustadas
const Footer = styled.footer`
  text-align: center;
  margin-top: 40px;
  padding: 15px;
  background-color: #244c3c; /* Fundo verde escuro */
  color: #ffffff !important;  /* Cor do texto ajustada para branco */
  width: 100%;
`;

const Debentures = () => {
  const [debentures, setDebentures] = useState([]);

  useEffect(() => {
    setDebentures(debenturesData);
  }, []);

  return (
    <Container>
      {/* Logotipo do Global Force Bank */}
      <div style={{ textAlign: 'center', marginBottom: '20px' }}>
        <img src={gbfLogo} alt="Global Force Bank Logo" style={{ width: '150px' }} />
      </div>

      <Title>Ofertas de Debêntures</Title>

      {/* Lista de ofertas em formato de cartões */}
      <OfferList>
        {debentures.map((offer) => (
          <OfferCard key={offer.id}>
            <OfferTitle>{offer.emissor}</OfferTitle>
            <OfferDetails>
              <OfferValue>Valor: R$ {offer.valor}</OfferValue>
              <p>Taxa de Juros: {offer.taxa_juros}%</p>
              <p>Prazo: {offer.prazo} meses</p>
              <p>Meta de Captação: R$ {offer.meta_captação}</p>
              <p>Investimento Mínimo: R$ {offer.valor_minimo_investidor}</p>
              <p>Período de Captação: {offer.data_inicio} até {offer.data_termino}</p>
            </OfferDetails>
            <InvestButton>Investir</InvestButton>
          </OfferCard>
        ))}
      </OfferList>

      <Footer>
        <p>&copy; 2024 Global Force Bank. Todos os direitos reservados.</p>
      </Footer>
    </Container>
  );
};

export default Debentures;

