import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import gbfLogo from '../assets/gbflogo.png';

// Header com Menu
const Header = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  background: linear-gradient(45deg, #ddba33, #e0c878);
  color: #244c3c;
`;

const Logo = styled.img`
  width: 150px;
`;

const Nav = styled.nav`
  a {
    color: #244c3c;
    margin: 0 15px;
    text-decoration: none;
    font-weight: bold;
  }
`;

// Estilo do container e fundo
const LoginContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  background: linear-gradient(to bottom right, #ddba33, #f1da8f);
  padding: 20px;
`;

const LoginBox = styled.div`
  background: #fff;
  padding: 40px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  max-width: 400px;
  width: 100%;
`;

const Title = styled.h2`
  color: #244c3c;
  text-align: center;
  margin-bottom: 20px;
  font-size: 24px;
`;

const FormGroup = styled.div`
  margin-bottom: 20px;
`;

const Label = styled.label`
  display: block;
  color: #244c3c;
  font-size: 14px;
  margin-bottom: 5px;
`;

const Input = styled.input`
  width: 100%;
  padding: 12px;
  border: 1px solid #ddd;
  border-radius: 5px;
  font-size: 16px;
`;

const SubmitButton = styled.button`
  background-color: #244c3c;
  color: #fff;
  padding: 15px;
  border: none;
  border-radius: 5px;
  width: 100%;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;

  &:hover {
    background-color: #1e3a33;
  }
`;

const Footer = styled.footer`
  margin-top: 30px;
  text-align: center;
  color: #244c3c;
  font-size: 14px;
`;

const Login = () => {
  return (
    <>
      <Header>
        <Link to="/home">
          <Logo src={gbfLogo} alt="Global Force Bank Logo" />
        </Link>
        <Nav>
          <Link to="/login">Login</Link>
          <Link to="/register">Registrar</Link>
        </Nav>
      </Header>
      
      <LoginContainer>
        <LoginBox>
          <Title>Faça seu Login</Title>
          <form>
            <FormGroup>
              <Label>Email</Label>
              <Input type="email" placeholder="Digite seu email" required />
            </FormGroup>

            <FormGroup>
              <Label>Senha</Label>
              <Input type="password" placeholder="Digite sua senha" required />
            </FormGroup>

            <SubmitButton type="submit">Entrar</SubmitButton>
          </form>
          <Footer>
            Ainda não tem uma conta? <Link to="/register">Registre-se</Link>
          </Footer>
        </LoginBox>
      </LoginContainer>
    </>
  );
};

export default Login;

