// Investments.js
import React, { useState } from 'react';
import styled from 'styled-components';
import gbfLogo from '../assets/gbflogo.png';

// Definindo os estilos principais usando styled-components
const InvestmentsContainer = styled.div`
  font-family: 'Roboto', sans-serif;
  color: #244c3c;
  background-color: #f4f8f7;
  padding: 30px;
  border-radius: 10px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
  max-width: 900px;
  margin: 20px auto;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  gap: 15px;
  margin-bottom: 25px;
`;

const Logo = styled.img`
  width: 60px;
  height: auto;
`;

const Title = styled.h1`
  font-size: 26px;
  color: #244c3c;
  font-weight: bold;
`;

const Description = styled.div`
  font-size: 16px;
  line-height: 1.6;
  margin-bottom: 35px;
`;

const Button = styled.button`
  background-color: #244c3c;
  color: #ffffff;
  border: none;
  padding: 12px 25px;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s;
  margin-top: 15px;

  &:hover {
    background-color: #1e3b32;
  }
`;

const TransferScreen = styled.div`
  background-color: #ffffff;
  border: 1px solid #ddd;
  border-radius: 10px;
  padding: 25px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.05);
  margin-top: 20px;
`;

const Step = styled.div`
  margin-bottom: 20px;

  label {
    display: block;
    font-size: 14px;
    color: #555;
    margin-bottom: 5px;
  }

  input {
    padding: 12px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 14px;
    width: 100%;

    &:focus {
      outline: none;
      border-color: #244c3c;
    }
  }

  select {
    padding: 12px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 14px;
    width: 100%;
    margin-top: 5px;

    &:focus {
      outline: none;
      border-color: #244c3c;
    }
  }
`;

// Componente principal
const Investments = () => {
  const [showTransferScreen, setShowTransferScreen] = useState(false);
  const [step, setStep] = useState(1);

  const handleNextStep = () => {
    if (step < 5) {
      setStep(step + 1);
    } else {
      alert("Transferência concluída com sucesso!");
      setShowTransferScreen(false); // Reinicia o fluxo após a conclusão
      setStep(1); // Reinicia para o primeiro passo
    }
  };

  const handleStartTransfer = () => {
    setShowTransferScreen(true);
  };

  return (
    <InvestmentsContainer>
      <Header>
        <Logo src={gbfLogo} alt="Global Force Bank Logo" />
        <Title>GFB Investimentos</Title>
      </Header>
      <Description>
        <p>
          A GFB Investimentos é a plataforma de investimentos da Global Force Bank, oferecendo diversas opções para todos os perfis de investidores. 
          Para entrar em contato, basta ligar para o número 400x0000 ou 0800 000 0000.
        </p>
      </Description>
      {!showTransferScreen ? (
        <Button onClick={handleStartTransfer}>Iniciar Transferência</Button>
      ) : (
        <TransferScreen>
          {step === 1 && (
            <Step>
              <label>Etapa 1: Realizar o login</label>
              <input type="text" placeholder="Usuário ou Email" />
              <input type="password" placeholder="Senha" />
            </Step>
          )}
          {step === 2 && (
            <Step>
              <label>Etapa 2: Selecionar Carteira</label>
              <select>
                <option>Carteira 1</option>
                <option>Carteira 2</option>
                <option>Carteira 3</option>
              </select>
            </Step>
          )}
          {step === 3 && (
            <Step>
              <label>Etapa 3: Preencher com o valor desejado</label>
              <input type="number" placeholder="Valor da Transferência" />
            </Step>
          )}
          {step === 4 && (
            <Step>
              <label>Etapa 4: Selecionar a conta de destino</label>
              <select>
                <option>Conta de Investimento 1</option>
                <option>Conta de Investimento 2</option>
              </select>
            </Step>
          )}
          {step === 5 && (
            <Step>
              <label>Etapa 5: Confirmar Transferência</label>
              <p>Revise os detalhes e clique em continuar para finalizar.</p>
            </Step>
          )}
          <Button onClick={handleNextStep}>
            {step < 5 ? "Continuar" : "Finalizar Transferência"}
          </Button>
        </TransferScreen>
      )}
    </InvestmentsContainer>
  );
};

export default Investments;

