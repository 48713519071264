import React, { useState } from 'react';
import styled from 'styled-components';
import logo from '../assets/gbflogo.png';

const CadastroContainer = styled.div`
  font-family: Arial, sans-serif;
  color: #333;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #f8f9fa;
  padding: 40px;
`;

const Header = styled.header`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
  width: 100%;
  background-color: #ddba33; /* Amarelo dourado */
  img {
    width: 150px;
    margin-right: 10px;
  }
`;

const Title = styled.h1`
  color: #244c3c; /* Verde escuro para contraste */
  font-size: 24px;
  font-weight: 600;
`;

const Form = styled.form`
  width: 100%;
  max-width: 800px;
  background: #ffffff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
`;

const Section = styled.section`
  margin-bottom: 20px;
`;

const SectionTitle = styled.h2`
  font-size: 20px;
  font-weight: 600;
  color: #244c3c;
  margin-bottom: 10px;
`;

const Label = styled.label`
  font-weight: 500;
  margin-bottom: 5px;
`;

const Input = styled.input`
  padding: 10px;
  margin-bottom: 15px;
  width: 100%;
  border: 1px solid #ced4da;
  border-radius: 4px;
`;

const Select = styled.select`
  padding: 10px;
  margin-bottom: 15px;
  width: 100%;
  border: 1px solid #ced4da;
  border-radius: 4px;
`;

const Button = styled.button`
  background-color: #244c3c; /* Verde escuro */
  color: white;
  padding: 12px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  &:hover {
    background-color: #1e3e33;
  }
`;

const CadastroClientePF = () => {
  const [formData, setFormData] = useState({
    CodigoUSO_INTERNO: '',
    DadosPessoais: {
      NomeCompleto: '',
      CPF: '',
      TipoDocumentoIdentificacao: '',
      NumeroDocumentoIdentificacao: '',
      DataEmissao: '',
      OrgaoEmissor: '',
      UFEmissor: '',
      Sexo: '',
      DataNascimento: '',
      LocalNascimento: '',
      UF: '',
      PaisNascimento: '',
      PaisResidenciaFiscal: '',
      NIF: '',
      NomePai: '',
      NomeMae: '',
      EstadoCivil: '',
      CPFConjuge: '',
      NomeConjuge: '',
    },
    DadosResidenciais: {
      Logradouro: '',
      Numero: '',
      Complemento: '',
      Bairro: '',
      Cidade: '',
      UF: '',
      CEP: '',
      TelefoneResidencial: '',
      TelefoneCelular: '',
      Email: '',
    },
    DadosComerciais: {
      Profissao: '',
      InstituicaoTrabalho: '',
      CargoFuncao: '',
      Endereco: {
        Logradouro: '',
        Numero: '',
        Complemento: '',
        Bairro: '',
        Cidade: '',
        UF: '',
        CEP: '',
      },
      TelefoneComercial: '',
      TelefoneCelularComercial: '',
      EmailComercial: '',
      EnderecoEnvioCorrespondencia: '',
    },
    DadosBancarios: [
      {
        BancoNome: '',
        NumeroAgencia: '',
        NumeroConta: '',
        ContaConjunta: '',
      },
    ],
    SituacaoFinanceiraPatrimonial: {
      RendimentosMensais: '',
      OutrosRendimentosAnual: '',
      Patrimonio: '',
    },
    Declaracoes: {
      PossuiCotitular: '',
      OperaPorContaPropria: '',
      NomeTitularTerceiro: '',
      TransmissaoOrdensPorProcurador: '',
      DeclaracaoPessoaVinculada: '',
      PessoaPoliticamenteExposta: '',
      ConsideradoUS_Person: '',
      CidadaniaNacionalidadeAdicional: '',
      ResidenciaPermanenteOutrosPaises: '',
      DomicilioFiscalOutroPais: '',
    },
    ConsentimentoParaTratamentoDadosPessoais: {
      ConcordoComTratamentoDadosPessoais: '',
    },
    Assinatura: {
      LocalData: '',
      Assinatura: '',
    },
    DeclaracaoResponsavelCadastramento: {
      LocalData: '',
      ArenaCapital: '',
    },
  });

  const handleInputChange = (section, field) => (e) => {
    const { value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [section]: {
        ...prevData[section],
        [field]: value,
      },
    }));
  };

  return (
    <CadastroContainer>
      <Header>
        <img src={logo} alt="Global Force Bank Logo" />
        <Title>Cadastro de Cliente PF</Title>
      </Header>

      <Form>
        <Section>
          <SectionTitle>Dados Pessoais</SectionTitle>
          <Label>Nome completo (sem abreviações)</Label>
          <Input
            type="text"
            value={formData.DadosPessoais.NomeCompleto}
            onChange={handleInputChange('DadosPessoais', 'NomeCompleto')}
          />
          <Label>CPF</Label>
          <Input
            type="text"
            value={formData.DadosPessoais.CPF}
            onChange={handleInputChange('DadosPessoais', 'CPF')}
          />
          <Label>Sexo</Label>
          <Select
            value={formData.DadosPessoais.Sexo}
            onChange={handleInputChange('DadosPessoais', 'Sexo')}
          >
            <option value="">Selecione</option>
            <option value="Masculino">Masculino</option>
            <option value="Feminino">Feminino</option>
            <option value="Outro">Outro</option>
          </Select>
          {/* Outros campos de dados pessoais */}
        </Section>

        <Section>
          <SectionTitle>Dados Residenciais</SectionTitle>
          <Label>Logradouro</Label>
          <Input
            type="text"
            value={formData.DadosResidenciais.Logradouro}
            onChange={handleInputChange('DadosResidenciais', 'Logradouro')}
          />
          <Label>Número</Label>
          <Input
            type="text"
            value={formData.DadosResidenciais.Numero}
            onChange={handleInputChange('DadosResidenciais', 'Numero')}
          />
          {/* Outros campos de dados residenciais */}
        </Section>

        <Section>
          <SectionTitle>Dados Comerciais</SectionTitle>
          <Label>Profissão</Label>
          <Input
            type="text"
            value={formData.DadosComerciais.Profissao}
            onChange={handleInputChange('DadosComerciais', 'Profissao')}
          />
          <Label>Instituição em que trabalha</Label>
          <Input
            type="text"
            value={formData.DadosComerciais.InstituicaoTrabalho}
            onChange={handleInputChange('DadosComerciais', 'InstituicaoTrabalho')}
          />
          {/* Outros campos de dados comerciais */}
        </Section>

        <Section>
          <SectionTitle>Dados Bancários</SectionTitle>
          <Label>Banco (N° e Nome)</Label>
          <Input
            type="text"
            value={formData.DadosBancarios[0].BancoNome}
            onChange={(e) => handleInputChange('DadosBancarios', 'BancoNome')(e)}
          />
          <Label>N° Agência</Label>
          <Input
            type="text"
            value={formData.DadosBancarios[0].NumeroAgencia}
            onChange={(e) => handleInputChange('DadosBancarios', 'NumeroAgencia')(e)}
          />
          <Label>Conta conjunta?</Label>
          <Select
            value={formData.DadosBancarios[0].ContaConjunta}
            onChange={(e) => handleInputChange('DadosBancarios', 'ContaConjunta')(e)}
          >
            <option value="">Selecione</option>
            <option value="Sim">Sim</option>
            <option value="Não">Não</option>
          </Select>
        </Section>

        <Button type="submit">Enviar Cadastro</Button>
      </Form>
    </CadastroContainer>
  );
};

export default CadastroClientePF;

