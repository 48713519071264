import React from 'react';
import styled from 'styled-components';
import logo from '../assets/gbflogo.png'; // Certifique-se de que o caminho do logotipo está correto
import { useNavigate } from 'react-router-dom'; // Para navegação entre páginas

// Estilos para o componente Home com fundo gradiente azul para cinza chumbo
const HomeContainer = styled.div`
  font-family: 'Montserrat', sans-serif; /* Fonte moderna */
  color: #333;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100vh;
  background: linear-gradient(180deg, #011E3B 0%, #343a40 100%); /* Gradiente de azul para cinza chumbo */
  text-align: center;
  padding-top: 50px;
`;

const Logo = styled.div`
  background-image: url(${logo});
  background-size: 65%; /* Aumenta o tamanho da logo */
  background-repeat: no-repeat;
  background-position: center;
  width: 100%;
  height: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Title = styled.h1`
  font-size: 45px;
  font-weight: 700;
  background: linear-gradient(90deg, #ffffff, #011E3B); /* Gradiente de texto */
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
  margin-bottom: 50px;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2);
`;

const ButtonContainer = styled.div`
  display: flex;
  gap: 20px;
  margin-top: -50px;
`;

const Button = styled.button`
  font-family: 'Montserrat', sans-serif; /* Aplica a fonte Montserrat */
  font-size: 16px;
  font-weight: bold;
  background: linear-gradient(90deg, #ddba33, #b5972d); /* Gradiente de fundo a partir de #ddba33 */
  color: white;
  padding: 15px 25px;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);

  &:hover {
    transform: scale(1.05);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.25);
  }
`;

const Home = () => {
  const navigate = useNavigate();

  // Funções para navegação
  const handleInstitutionalClick = () => {
    navigate('/institucional'); // Navega para a página Institucional
  };

  const handleAppClick = () => {
    navigate('/app'); // Navega para a página do App
  };

  return (
    <HomeContainer>
      <Title>Bem-vindo</Title>
      <Logo />
      <ButtonContainer>
        <Button onClick={handleInstitutionalClick}>Institucional</Button>
        <Button onClick={handleAppClick}>BankAPP</Button>
      </ButtonContainer>
    </HomeContainer>
  );
};

export default Home;

