
import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './components/Home'; // Certifique-se de que este caminho está correto
import Login from './components/Login'; // Certifique-se de que este caminho está correto
import Register from './components/Register'; // Certifique-se de que este caminho está correto
import Transactions from './components/Transactions'; // Certifique-se de que este caminho está correto
import Investments from './components/Investments'; // Certifique-se de que este caminho está correto
import Header from './components/Header'; // Inclua seu componente Header, se necessário
import Footer from './components/Footer'; // Inclua seu componente Footer, se necessário
import Debentures from './components/Debentures';
import CadastroClientePF from './components/CadastroClientePF';
import Institucional from './components/Institucional';



function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/home" element={<Home />} />
        <Route path="/login" element={<Login />} />
        <Route path="/register" element={<Register />} />
        <Route path="/transactions" element={<Transactions />} />
        <Route path="/investments" element={<Investments />} />
        <Route path="/investments" component={Investments} />
        <Route path="/debentures" element={<Debentures />} />
        <Route path="/cadastro-cliente-pf" element={<CadastroClientePF />} />
        <Route path="/institucional" element={<Institucional />} /> {/* Nova rota */}

        {/* Adicione outras rotas conforme necessário */}
      </Routes>
    </Router>
  );
}

export default App;

