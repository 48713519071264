import React from 'react';
import styled from 'styled-components';
import Svg1 from '../assets/1.svg';
import Svg2 from '../assets/2.svg';
import Svg3 from '../assets/3.svg';
import Svg4 from '../assets/4.svg';

// Importar a fonte moderna, como Montserrat ou Roboto
import '@fontsource/montserrat';

// Estilos para o container principal com fundo gradiente
const PageContainer = styled.div`
  background: linear-gradient(180deg, #011E3B 0%, #013A6B 100%);
  min-height: 100vh;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Title = styled.h1`
  font-family: 'Montserrat', sans-serif; /* Fonte moderna */
  font-size: 36px;
  font-weight: bold;
  background: linear-gradient(90deg, #ffffff, #011E3B); /* Gradiente de texto */
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent; /* Faz o gradiente de texto aparecer */
  background-clip: text;
  text-fill-color: transparent;
  margin-bottom: 40px;
`;

const SvgContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const SvgImage = styled.img`
  width: 100%;
  max-width: 800px;
  height: auto;
  margin-bottom: 20px;
`;

const Institucional = () => {
  return (
    <PageContainer>
      <Title>Institucional</Title>
      <SvgContainer>
        <SvgImage src={Svg1} alt="SVG 1" />
        <SvgImage src={Svg2} alt="SVG 2" />
        <SvgImage src={Svg3} alt="SVG 3" />
        <SvgImage src={Svg4} alt="SVG 4" />
      </SvgContainer>
    </PageContainer>
  );
};

export default Institucional;

