import React, { useState } from 'react';
import '../styles/register.css'; // Verifique se o caminho está correto
import axios from 'axios'; // Para realizar requisições HTTP

function Register() {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(false); // Estado para feedback de carregamento

  const handleSubmit = async (event) => {
    event.preventDefault();

    // Verifica se as senhas coincidem
    if (password !== confirmPassword) {
      setMessage("As senhas não coincidem.");
      return;
    }

    // Objeto com os dados do novo usuário
    const newUser = {
      name,
      email,
      password,
    };

    try {
      setLoading(true); // Início do feedback de carregamento
      // Faz a requisição para a API para registrar o usuário
      const response = await axios.post('https://globalforcebank.com.br/api/register/', newUser);
      if (response.status === 201) {
        setMessage("Cadastro efetuado com sucesso!"); // Mensagem de sucesso
      } else {
        setMessage("Ocorreu um erro. Tente novamente.");
      }
    } catch (error) {
      // Captura e exibe mensagens de erro específicas do servidor, se disponíveis
      if (error.response && error.response.data) {
        setMessage(error.response.data.error || "Erro ao cadastrar. Tente novamente.");
      } else {
        setMessage("Erro ao conectar com o servidor.");
      }
    } finally {
      setLoading(false); // Final do feedback de carregamento
    }
  };

  return (
    <div className="register-container">
      <div className="register-header">
        <h1>Registrar</h1>
      </div>
      {message && <div className="notification">{message}</div>}
      <form className="register-form" onSubmit={handleSubmit}>
        <input
          type="text"
          placeholder="Nome"
          value={name}
          onChange={(e) => setName(e.target.value)}
          required
        />
        <input
          type="email"
          placeholder="Email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
        />
        <input
          type="password"
          placeholder="Senha"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          required
        />
        <input
          type="password"
          placeholder="Confirmar Senha"
          value={confirmPassword}
          onChange={(e) => setConfirmPassword(e.target.value)}
          required
        />
        <button type="submit" disabled={loading}>
          {loading ? "Registrando..." : "Registrar"}
        </button>
      </form>
      <div className="register-footer">
        <p>Já possui uma conta? <a href="/login">Faça login</a></p>
      </div>
    </div>
  );
}

export default Register;

