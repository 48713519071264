// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* styles.css */

/* Configurações gerais para o corpo da página */
body {
  margin: 0;
  padding: 0;
  font-family: Arial, sans-serif;
  background: linear-gradient(to bottom, #ddba33, #ffffff);
  color: #244c3c;
}

/* Definições padrão para headers, seções e textos */
h1, h2, h3, h4, h5, h6 {
  color: #244c3c;
  margin: 0;
  padding: 10px;
}

p {
  color: #244c3c;
  line-height: 1.5;
  padding: 10px;
}

/* Configuração dos botões */
button {
  background-color: #ddba33;
  color: #244c3c;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s;
}

button:hover {
  background-color: #c9a72c;
}

/* Estilização de links */
a {
  color: #244c3c;
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

/* Configurações para contêineres e layouts */
.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
}

.header, .footer {
  background-color: #ffffff;
  padding: 20px;
  text-align: center;
  color: #244c3c;
}

`, "",{"version":3,"sources":["webpack://./src/styles.css"],"names":[],"mappings":"AAAA,eAAe;;AAEf,gDAAgD;AAChD;EACE,SAAS;EACT,UAAU;EACV,8BAA8B;EAC9B,wDAAwD;EACxD,cAAc;AAChB;;AAEA,oDAAoD;AACpD;EACE,cAAc;EACd,SAAS;EACT,aAAa;AACf;;AAEA;EACE,cAAc;EACd,gBAAgB;EAChB,aAAa;AACf;;AAEA,4BAA4B;AAC5B;EACE,yBAAyB;EACzB,cAAc;EACd,YAAY;EACZ,kBAAkB;EAClB,eAAe;EACf,eAAe;EACf,iCAAiC;AACnC;;AAEA;EACE,yBAAyB;AAC3B;;AAEA,yBAAyB;AACzB;EACE,cAAc;EACd,qBAAqB;AACvB;;AAEA;EACE,0BAA0B;AAC5B;;AAEA,6CAA6C;AAC7C;EACE,iBAAiB;EACjB,cAAc;EACd,aAAa;AACf;;AAEA;EACE,yBAAyB;EACzB,aAAa;EACb,kBAAkB;EAClB,cAAc;AAChB","sourcesContent":["/* styles.css */\n\n/* Configurações gerais para o corpo da página */\nbody {\n  margin: 0;\n  padding: 0;\n  font-family: Arial, sans-serif;\n  background: linear-gradient(to bottom, #ddba33, #ffffff);\n  color: #244c3c;\n}\n\n/* Definições padrão para headers, seções e textos */\nh1, h2, h3, h4, h5, h6 {\n  color: #244c3c;\n  margin: 0;\n  padding: 10px;\n}\n\np {\n  color: #244c3c;\n  line-height: 1.5;\n  padding: 10px;\n}\n\n/* Configuração dos botões */\nbutton {\n  background-color: #ddba33;\n  color: #244c3c;\n  border: none;\n  padding: 10px 20px;\n  cursor: pointer;\n  font-size: 16px;\n  transition: background-color 0.3s;\n}\n\nbutton:hover {\n  background-color: #c9a72c;\n}\n\n/* Estilização de links */\na {\n  color: #244c3c;\n  text-decoration: none;\n}\n\na:hover {\n  text-decoration: underline;\n}\n\n/* Configurações para contêineres e layouts */\n.container {\n  max-width: 1200px;\n  margin: 0 auto;\n  padding: 20px;\n}\n\n.header, .footer {\n  background-color: #ffffff;\n  padding: 20px;\n  text-align: center;\n  color: #244c3c;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
