import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Link, useNavigate } from 'react-router-dom';
import gbfLogo from '../assets/gbflogo.png'; // Importando o logotipo

// Container principal estilizado
const TransactionsContainer = styled.div`
  font-family: 'Poppins', sans-serif;
  color: #244c3c;
  max-width: 1000px;
  margin: 0 auto;
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 10px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
`;

// Cabeçalho com logo e navegação
const Header = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 20px;
  border-bottom: 2px solid #ddba33;
`;

const Logo = styled.img`
  height: 70px;
`;

const Navbar = styled.nav`
  display: flex;
  gap: 15px;
`;

const MenuLink = styled(Link)`
  text-decoration: none;
  color: #244c3c;
  font-weight: 600;
  font-size: 18px;
  transition: color 0.3s;

  &:hover {
    color: #ddba33;
  }
`;

// Seção de transações
const TransactionsSection = styled.section`
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

// Botões estilizados
const Button = styled.button`
  background-color: #ddba33;
  color: #fff;
  padding: 12px 25px;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  font-weight: bold;
  font-size: 16px;
  transition: background-color 0.3s;
  width: 100%;
  text-align: center;

  &:hover {
    background-color: #c7a027;
  }
`;

const Footer = styled.footer`
  margin-top: 40px;
  text-align: center;
  color: #888;
`;

function Transactions() {
  const [transactions, setTransactions] = useState([]);
  const [filter, setFilter] = useState('all');
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    // Simulação de uma chamada de API para buscar transações
    const fetchTransactions = async () => {
      try {
        setLoading(true);
        const response = await fetch('/api/transactions'); // Endereço da API a ser substituído
        const data = await response.json();
        setTransactions(data);
      } catch (error) {
        console.error('Erro ao buscar transações:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchTransactions();
  }, []);

  // Filtro de transações
  const filteredTransactions = transactions.filter((transaction) => {
    if (filter === 'all') return true;
    return transaction.type === filter;
  });

  // Função para navegar para o componente Investments.js
  const goToInvestments = () => {
    navigate('/investments');
  };

  return (
    <TransactionsContainer>
      <Header>
        <Logo src={gbfLogo} alt="Global Force Bank Logo" />
        <Navbar>
          <MenuLink to="/">Início</MenuLink>
        </Navbar>
      </Header>

      <TransactionsSection>
        <h1>Transações</h1>
        <div className="transactions-filters">
          <label htmlFor="filter">Filtrar por:</label>
          <select
            id="filter"
            value={filter}
            onChange={(e) => setFilter(e.target.value)}
            style={{
              padding: '8px',
              borderRadius: '5px',
              borderColor: '#ddba33',
              fontSize: '16px',
              marginBottom: '20px',
            }}
          >
            <option value="all">Todas</option>
            <option value="deposit">Depósitos</option>
            <option value="withdrawal">Saques</option>
            <option value="transfer">Transferências</option>
          </select>
        </div>

        <Button onClick={() => alert('Iniciar nova transferência')}>Nova Transferência</Button>
        <Button onClick={() => alert('Realizar pagamento via PIX')}>Pagamento via PIX</Button>
        <Button onClick={() => alert('Pagar uma conta')}>Pagamento de Contas</Button>
        <Button onClick={() => alert('Realizar recarga de celular')}>Recarga de Celular</Button>
        {/* Botão para acessar o componente Investments.js */}
        <Button onClick={goToInvestments}>Ir para Investimentos</Button>
      </TransactionsSection>

      {loading ? (
        <p style={{ textAlign: 'center', color: '#244c3c' }}>Carregando transações...</p>
      ) : (
        <table style={{ width: '100%', borderCollapse: 'collapse', marginTop: '20px' }}>
          <thead>
            <tr style={{ backgroundColor: '#ddba33', color: '#fff' }}>
              <th style={{ padding: '10px', border: '1px solid #ddd' }}>Data</th>
              <th style={{ padding: '10px', border: '1px solid #ddd' }}>Tipo</th>
              <th style={{ padding: '10px', border: '1px solid #ddd' }}>Valor (R$)</th>
              <th style={{ padding: '10px', border: '1px solid #ddd' }}>Descrição</th>
            </tr>
          </thead>
          <tbody>
            {filteredTransactions.map((transaction) => (
              <tr key={transaction.id} style={{ borderBottom: '1px solid #ddd' }}>
                <td style={{ padding: '10px' }}>{transaction.date}</td>
                <td style={{ padding: '10px' }}>{transaction.type}</td>
                <td style={{ padding: '10px' }}>{transaction.amount.toFixed(2)}</td>
                <td style={{ padding: '10px' }}>{transaction.description}</td>
              </tr>
            ))}
          </tbody>
        </table>
      )}

      <Footer>© 2024 Global Force Bank - Todos os direitos reservados</Footer>
    </TransactionsContainer>
  );
}

export default Transactions;

